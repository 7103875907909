import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import safeStringify from "fast-safe-stringify";

import { GetStaticProps, NextPage } from "next";

import { ContentfulNavigation } from "../@types/contentfulNavigation";
import SadDocument from "../components/icons/server-error-document.svg";
import PageLayout from "../components/page/layout";
import ContentfulNavigationRequest from "../utils/graphQL/contentful/ContentfulNavigationRequest";
import getJointPageProperties, {
  JointPageProperties,
} from "../utils/page/getJointPageProperties";

interface ErrorPageProps {
  isPreview: boolean;
  previewData: any;
  navigation: ContentfulNavigation;
  jointPageProperties: JointPageProperties;
}

const ServerErrorPage: NextPage<ErrorPageProps> = ({
  navigation,
  jointPageProperties,
}) => {
  const theme = useTheme();
  return (
    <PageLayout
      title={"Seite nicht gefunden"}
      navigation={navigation}
      logoHeader={jointPageProperties.siteSettings.logoDark}
      footer={jointPageProperties.footerProps}
      metadata={{
        internal: "Fehler",
        title: "Seite nicht gefunden",
        description: "",
        noIndex: true,
        noFollow: true,
      }}
    >
      <Grid
        container
        sx={{
          mx: "auto",
          boxSizing: "border-box",
          margin: theme.spacing(1),

          textAlign: "center",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          width: "unset",
        }}
      >
        <SadDocument
          style={{
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(7),
            width: "128px",
            height: "128px",
            color: theme.components?.ErrorPageIcon.color,
          }}
        ></SadDocument>
        <Typography variant="h2" sx={{ marginBottom: theme.spacing(3) }}>
          Ohje, leider ist etwas schief gelaufen.
        </Typography>
        <Typography sx={{ marginBottom: theme.spacing(3) }}>
          Wir arbeiten bereits an einer Lösung. Bitte versuche es später erneut.
        </Typography>
      </Grid>
    </PageLayout>
  );
};

export default ServerErrorPage;

export const getStaticProps: GetStaticProps<ErrorPageProps> = async (
  context
) => {
  const [navigation, jointPageProperties] = await Promise.all([
    new ContentfulNavigationRequest().getNavigation(),
    getJointPageProperties(context),
  ]);

  const propsData = safeStringify({
    isPreview: context.preview || false,
    previewData: context.previewData || {
      env: process.env.NEXT_PUBLIC_CONTENTFUL_ENV,
      internal: "",
    },
    navigation,
    jointPageProperties,
  });

  const props = JSON.parse(propsData);

  return {
    props,
  };
};
